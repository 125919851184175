import { PortableText, PortableTextProps } from '@portabletext/react';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import * as styles from './job.module.scss';

interface Props {
  title: string;
  contract: string;
  time: string;
  location: string;
  content: PortableTextProps['value'];
}

const Job: FC<Props> = ({ title, contract, time, location, content }) => {

  const { general } = useStaticQuery(graphql`
    {
      general: sanityGeneral {
        email
      }
    }
  `);

  return (
    <div className={styles.job}>
      <h3 className={styles.title}>
        {title}
      </h3>
      <h4 className={styles.information}>
        {contract} - {time} - {location}
      </h4>
      <div className={styles.contentContainer}>
        <PortableText value={content} />
      </div>

      <a href={`mailto:${general.email}?subject=Candidature%20:%20${title}%20(${contract})`} className={styles.candidateButton}>
        Postuler
      </a>
    </div>
  );
};

export default Job;